import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
        menu:[],
        type:0,
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
            state.type = 0;
            state.menu = []
            window.location.href = '/#'+'/login?type='+(this.type==1?'':this.type==2?'company':this.type==6?'masses':'')
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
        },
        setButtons(state, payload) {
            state.buttons = payload.filter(item=>item.name!='hidden');
        },
        SET_MENU(state,payload){
            state.menu = payload
        },
        setType(state,type){
            state.type = type
        },
    },
    actions: {
        setMenu(ctx,payload){
            ctx.commit('SET_MENU',payload)
        },
        setType(ctx,type){
            ctx.commit('setType',type)
        },
        setAdminInfo(ctx,userInfo){
            ctx.commit('setAdminInfo',userInfo)
        }
    },
    modules: {
        
    },
    plugins: [
        createPersistedState()
    ]
})
