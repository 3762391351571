<template>
  <router-view></router-view>
</template>
<script>
export default {
  created() {
    this.setType()
  },
  watch:{
    '$route':function(){
      this.setType()
    }
  },
  methods: {
    setType() {
      if (this.$store.state.type) {
          if(this.$store.state.type==1){
              document.title = '军屯镇派出所管理平台'
          }
          if(this.$store.state.type==2){
              document.title = '军屯镇派出所管理平台'
          }
          if(this.$store.state.type == 6){
              document.title = '军屯镇派出所管理平台'
          }

        // document.title = this.$store.state.type == 1 ? '邻水县“千名干部访千企”服务平台' : '“1+N”服务群众管理后台'
      }
    }
  }
}
</script>
<style src="@/assets/css/element-m.css"></style>
<style src="@/assets/css/app.css"></style>
<style>
.el-message {
  z-index: 3002 !important;
}
.el-page-header__content{
    font-size: 15px;
}
</style>